<template>
  <form @submit.prevent="handleSubmit" novalidate style="position: relative">
    <b-loading v-model="loading" :is-full-page="false"></b-loading>

    <b-notification v-if="form.errors.has('NOFIELD')" type="is-danger" role="alert">
      {{ form.errors.get('NOFIELD') }}
    </b-notification>

    <div class="block">
      <div>
        <AjaxSelectField
          label="Select a provider"
          placeholder="e.g. Healthcare Facility"
          :auto-select="true"
          :options="activeProviderOptions"
          :errors="form.errors.get('provider_id')"
          v-model="form.provider_id"
        />
        <AjaxSelectField
          :loading="payerOptions.length"
          label="Select a payer"
          placeholder="e.g. Blue Cross"
          :options="payerOptions"
          :value="form.payer_id"
          :errors="form.errors.get('payer_id')"
          @input="handlePayerSelected"
        />
      </div>
    </div>

    <hr />

    <div v-if="schema">
      <div class="columns" v-if="showCombos">
        <div class="column">
          <b-field label="Select Fields">
            <b-select expanded v-model="form.selectedCombo">
              <option v-for="(option, index) in combos" :value="index" :key="index">
                {{ option.join(', ') }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <a
          v-if="!showCombos && combos.length > 1"
          @click="showCombos = !showCombos"
          style="padding: 0.75rem"
        >
          <small>Have different information?</small>
        </a>
      </div>

      <div class="columns">
        <div class="column" v-if="combos[form.selectedCombo].includes('groupNumber')">
          <TextField
            v-model="form.groupNumber"
            :schema="schema.groupNumber"
            :errors="form.errors.get('groupNumber')"
          ></TextField>
        </div>
        <div class="column" v-if="combos[form.selectedCombo].includes('memberId')">
          <TextField
            v-model="form.memberId"
            :schema="schema.memberId"
            :errors="form.errors.get('memberId')"
          ></TextField>
        </div>
        <div class="column" v-if="combos[form.selectedCombo].includes('patientBirthDate')">
          <DateField
            v-model="form.patientBirthDate"
            :schema="schema.patientBirthDate"
            :errors="form.errors.get('patientBirthDate')"
          ></DateField>
        </div>
      </div>

      <div class="columns">
        <div class="column" v-if="combos[form.selectedCombo].includes('patientFirstName')">
          <TextField
            v-model="form.patientFirstName"
            :schema="schema.patientFirstName"
            :errors="form.errors.get('patientFirstName')"
          ></TextField>
        </div>
        <div class="column" v-if="combos[form.selectedCombo].includes('patientLastName')">
          <TextField
            v-model="form.patientLastName"
            :schema="schema.patientLastName"
            :errors="form.errors.get('patientLastName')"
          ></TextField>
        </div>
        <div class="column" v-if="combos[form.selectedCombo].includes('patientSSN')">
          <TextField
            v-model="form.patientSSN"
            :schema="schema.patientSSN"
            :errors="form.errors.get('patientSSN')"
          ></TextField>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <MultiCheckboxField
            v-if="schema.serviceType.multiple"
            v-model="serviceType"
            :schema="schema.serviceType"
            :errors="form.errors.get('serviceType')"
          ></MultiCheckboxField>
          <RadioField
            v-if="!schema.serviceType.multiple"
            v-model="form.serviceType"
            :schema="schema.serviceType"
            :errors="form.errors.get('serviceType')"
          ></RadioField>
        </div>
        <div class="column">
          <div class="block" v-if="schema.patientGender?.allowed">
            <RadioField
              v-model="form.patientGender"
              :schema="schema.patientGender"
              :errors="form.errors.get('patientGender')"
            ></RadioField>
          </div>
          <div class="block" v-if="schema.subscriberRelationship?.allowed">
            <RadioField
              v-model="form.subscriberRelationship"
              :schema="schema.subscriberRelationship"
              :errors="form.errors.get('subscriberRelationship')"
            ></RadioField>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns is-vcentered">
        <div class="column has-text-right is-one-quarter">
          <b-switch
            size="is-medium"
            v-model="useToday"
            :type="form.errors.has('asOfDate') ? 'is-danger' : ''"
            @input="$event === true && delete form['asOfDate']"
          >
            As Of Today
          </b-switch>
        </div>
        <div class="column">
          <b-field
            v-if="schema.asOfDate?.allowed && !useToday"
            :label="schema.asOfDate.label"
            :message="form.errors.get('asOfDate')"
            :type="{ 'is-danger': form.errors.has('asOfDate') }"
          >
            <b-datepicker
              v-model="form.asOfDate"
              :min-date="new Date(schema.asOfDate.min)"
              :max-date="new Date()"
              position="is-top-right"
              locale="en-US"
              placeholder="Click to select..."
              icon="calendar-today"
              :use-html5-validation="false"
              trap-focus
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column has-text-right is-one-quarter">
          <b-button :loading="loading" type="is-primary" size="is-medium" native-type="submit">
            Submit Inquiry
          </b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import request from '../request';
import Form from '../utils/form';
import AjaxSelectField from '../components/fields/AjaxSelectField.vue';
import DateField from '../components/fields/DateField.vue';
import MultiCheckboxField from '../components/fields/MultiCheckboxField.vue';
import RadioField from '../components/fields/RadioField.vue';
import TextField from '../components/fields/TextField.vue';

const defaultFormFields = {
  // Internal
  provider_id: null,
  payer_id: null,

  // Always used
  memberId: null,
  patientBirthDate: null,

  // Frequently used
  patientFirstName: null,
  patientLastName: null,
  patientGender: null,
  asOfDate: null,
  serviceType: null,
  subscriberRelationship: null,

  // Infrequently used
  groupNumber: null,
  patientSuffix: null,
  patientSSN: null,
  submitterId: null,
  parentId: null,

  // Field combination,
  selectedCombo: 0,
};

const copyableFormFields = [
  'provider_id',
  'payer_id',
  'memberId',
  'patientBirthDate',
  'patientFirstName',
  'patientLastName',
  'selectedCombo',
];

export default {
  name: 'InquiryForm',
  components: {
    AjaxSelectField,
    DateField,
    MultiCheckboxField,
    RadioField,
    TextField,
  },
  props: {
    // inquiry can be passed in to prepopulate form.
    // copy() method must be called to actually populate.
    inquiry: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      loading: true,

      payerOptions: [],
      providerOptions: [],
      form: new Form(defaultFormFields),
      schema: null,
      useToday: true,
      combos: [],
      showCombos: false,
      parentId: null,
    };
  },
  mounted() {
    Promise.all([
      request({ url: '/payers' }).then((resp) => (this.payerOptions = resp.data.payers)),
      request({ url: '/providers/compact' }).then(
        (resp) => (this.providerOptions = resp.data.providers)
      ),
    ]).finally(() => {
      if (this.inquiry !== undefined) {
        this.populateForm(this.inquiry.submission);
      }
      this.loading = false;
    });
  },
  computed: {
    activeProviderOptions() {
      return this.providerOptions.filter((opt) => opt.is_active);
    },
    serviceType: {
      get() {
        const multiple = this.schema?.serviceType?.multiple;
        const isArray = Array.isArray(this.form.serviceType);
        if (multiple && !isArray) {
          return [this.form.serviceType];
        }
        return this.form.serviceType;
      },
      set(value) {
        this.form.serviceType = value;
      },
    },
  },
  methods: {
    cleanForm() {
      this.schema = null;
      this.combos = null;
      this.form.selectedCombo = 0;
      this.form.errors.clear();

      for (let field in this.form.data()) {
        if (!copyableFormFields.includes(field)) {
          this.form[field] = null;
        }
      }
    },
    updateSchema(payer_id) {
      this.loading = true;
      let url = `/payers/${payer_id}/schema`;
      request({ url: url })
        .then((resp) => {
          this.schema = resp.data.schema.fields;
          this.combos = resp.data.schema.combos;
        })
        .catch(() => {
          this.schema = null;
          this.combos = null;
          this.form.errors.errors.NOFIELD = 'The payer is having issues.';
        })
        .finally(() => (this.loading = false));
    },
    handlePayerSelected(payer_id) {
      this.cleanForm();
      this.showCombos = false;
      this.form.payer_id = payer_id;
      if (payer_id == null) {
        return;
      }
      this.updateSchema(payer_id);
    },
    // populateForm fills in form fields with params. Used for InquiryCopy.
    populateForm(params) {
      for (const param in params) {
        // Never prepopulate the asOfDate.
        if (param === 'asOfDate') {
          continue;
        } else if (param === 'patientBirthDate') {
          this.$set(this.form, param, new Date(params[param]));
        } else {
          this.$set(this.form, param, params[param]);
        }
      }

      if (!this.providerOptions.find(({ id }) => id === this.form.provider_id)) {
        this.form.provider_id = null;
        this.form.errors.errors.provider_id =
          'The provider used is no longer available, please select a different one.';
      }
      if (!this.payerOptions.find(({ id }) => id === this.form.payer_id)) {
        this.form.payer_id = null;
        this.form.errors.errors.payer_id =
          'The payer used is no longer available, please select a different one.';
      }

      if (this.form.payer_id) {
        this.updateSchema(this.form.payer_id);
      }

      if (this.inquiry.id !== '') {
        this.form.parentId = this.inquiry.id;
      }
    },
    formIsValid() {
      // validate service types
      if (this.schema.serviceType.required) {
        if (!this.form.serviceType) {
          this.form.errors.record({ serviceType: 'Please select one or more service types.' });
        } else {
          // remove null values from serviceType
          if (Array.isArray(this.form.serviceType)) {
            this.form.serviceType = this.form.serviceType.filter((el) => el != null);
          }
          if (this.form.serviceType.length === 0) {
            this.form.errors.record({ serviceType: 'Please select one or more service types.' });
          }
        }
      }
      return this.form.errors.any() === false;
    },
    handleSubmit() {
      this.loading = true;
      this.form.errors.clear();

      // add parentId to form data if it exists
      // this means we are resubmitting an inquiry
      if (this.parentId) {
        this.form.parentId = this.parentId;
      }

      if (!this.formIsValid()) {
        this.loading = false;
        return;
      }

      // TODO: could use sanitizedData() to remove nulls, but doesn't seem necessary.
      this.form
        .post('/inquiries')
        .then((resp) => {
          let inq = resp.inquiry;

          // if the inquiry is not complete, we need to set the parentId
          // so we can use it as a parent for the next retry.
          if (inq.status !== 'complete' && inq.id !== '') {
            this.parentId = inq.id;
          }

          if (inq.status === 'complete') {
            this.$router.push({
              name: 'InquiryDetail',
              params: { id: inq.id },
            });
            // This early return keeps loading from being set to false before we transition.
            return;
          } else if (inq.status === 'error') {
            for (let key in inq.remote_errors) {
              if (key.startsWith('provider')) {
                this.form.errors.errors.NOFIELD = `There seems to be a problem with your configured provider information. (${key})`;
              }
              if (key.startsWith('payer')) {
                this.form.errors.errors.NOFIELD = `There seems to be a problem with this payer. (${key})`;
              }
            }
            this.form.errors.record(inq.remote_errors);
          } else if (inq.status === 'max-retry') {
            this.$buefy.toast.open({
              message: 'The payer is taking a long time to respond. Please try again later.',
              type: 'is-warning',
              duration: 20000,
            });
            this.$router.push({ name: 'InquiryList' });
          } else {
            this.form.errors.setGenericError();
          }
          // This is here instead of in finally() so we can short
          // circut, to keep 'loading' until we transition fully.
          this.loading = false;
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
          this.loading = false;
        });
    },
  },
};
</script>
